import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import SEO from '../components/SEO/SEO';
import Stopka from '../components/Stopka/Stopka';

const ErrorPage = () => {

	const [lang, setLang] = useState('pl')
	
	useEffect(() => {
	if (/^en\b/.test(navigator.language)) {
		setLang('en')
	}
	}, [])
	
	return (
		<div className="padding">
			<div className="container">
				<SEO />
				<main class="error-page">
					<h1>{lang === 'en' ? 'This page does not exist' : 'Podana strona nie istnieje'}</h1>
					<Link to={lang === 'en' ? '/en/' : '/'} className="link">
						{lang === 'en' ? 'Go back' : 'Powrót'}
					</Link>
				</main>
			</div>
			<Stopka />
		</div>
	);
};

export default ErrorPage;
